import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '@/components/mainDashboard'
import Zeiten from '@/components/mainZeiten'
import Angebote from '@/components/mainAngebote'
import Projekte from '@/components/mainProjekte'
import Rechnungen from '@/components/mainRechnungen'
import Vertrage from '@/components/mainVertrage'
import Kunden from '@/components/mainKunden'
import Webseiten from '@/components/mainWebseiten'
import Signin from '@/components/User/userSignin'
import Signup from '@/components/User/userSignup'
import Spiele from '@/components/mainSpiele'
import Aquisen from '@/components/mainAquise'

import Portfolio from '@/components/portfolio/mainPortfolio'
import portfolioKorpodesk from '@/components/portfolio/portfolioKorpodesk'

import authGuard from './auth-guard'


Vue.use(Router)

export default new Router({
  routes: [
    { path: '/portfolio', name: 'Portfolio', component: Portfolio },
    { path: '/korpodesk', name: 'Korpodesk', component: portfolioKorpodesk },
    { path: '/', name: 'Dashboard', component: Dashboard, beforeEnter: authGuard },
    { path: '/zeiten', name: 'Zeiten', component: Zeiten, beforeEnter: authGuard },
    { path: '/angebote', name: 'Angebote', component: Angebote, beforeEnter: authGuard },
    { path: '/projekte', name: 'Projekte', component: Projekte, beforeEnter: authGuard },
    { path: '/rechnungen', name: 'Rechnungen', component: Rechnungen, beforeEnter: authGuard },
    { path: '/vertrage', name: 'Verträge', component: Vertrage, beforeEnter: authGuard },
    { path: '/kunden', name: 'Kunden', component: Kunden, beforeEnter: authGuard },
    { path: '/webseiten', name: 'Webseiten', component: Webseiten, beforeEnter: authGuard },
    { path: '/aquisen', name: 'Aquisen', component: Aquisen, beforeEnter: authGuard },
    { path: '/spiele', name: 'Spiele', component: Spiele, beforeEnter: authGuard },
    {
      path: '/anmelden',
      name: 'Anmelden',
      component: Signin
    },
    {
      path: '/registrieren',
      name: 'Registrieren',
      component: Signup
    }
  ],
  mode: 'history'
})
