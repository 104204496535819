import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  vertrage: [],
  loading: false,
  error: null
}

// getters
const getters = {
  vertrag: (state) => (id) => {
    return state.vertrage.find(vertrag => vertrag.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadVertrage ({commit}) {
    commit('setLoading', true)
    console.log('Vertrage laden...')
    firebase.firestore().collection('vertrage').orderBy('vertragsnummer', 'desc')
    .onSnapshot(snapshot => {
      const vertrage = []
      snapshot.forEach(doc => {
        vertrage.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedVertrage', vertrage)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createVertrag ({commit, getters}, payload) {
    firebase.firestore().collection('vertrage').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteVertragData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('vertrage').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteVertrag', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateVertragData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('vertrage').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateVertrag', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedVertrage (state, payload) {
    state.vertrage = payload
  },
  createVertrag (state, payload) {
    state.vertrage.push(payload)
  },
  updateVertrag (state, payload) {
    const vertrag = state.vertrage.find(vertrag => {
      return vertrag.id === payload.id
    })
    if (payload.creatorID) {
      vertrag.creatorID = payload.creatorID
    }
    if (payload.date) {
      vertrag.date = payload.date
    }
    if (payload.bezahlt) {
      vertrag.bezahlt = payload.bezahlt
    }
    if (payload.fahrer) {
      vertrag.fahrer = payload.fahrer
    }
    if (payload.km) {
      vertrag.km = payload.km
    }
    if (payload.title) {
      vertrag.title = payload.title
    }
  },
  deleteVertrag (state, payload) {
    let array = state.vertrage
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.vertrage = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
