<template>
  <v-container>
    <v-row class="mt-12">
      <v-col cols="12" md="4" offset-md="4">
        <v-card class="rounded-lg">
          <v-card-title class="justify-center">
            Anmelden
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              class="my-4"
              outlined
              dense
              hide-details
              name="email"
              label="E-Mail"
              id="email"
              v-model="email"
              type="email"
              required>
            </v-text-field>
            <v-text-field
              class="my-4"
              outlined
              dense
              hide-details
              name="password"
              label="Passwort"
              id="password"
              v-model="password"
              type="password"
              required>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text to="/registrieren">Registrieren</v-btn>
            <v-spacer></v-spacer>
            <v-btn text class="primary" @click="onSignin()" :disabled="loading" :loading="loading">Einloggen</v-btn>
          </v-card-actions>
        </v-card>
        <v-btn class="mt-4" to="/" block text>Zurück zum Portfolio</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data () {
      return {
        email: '',
        password: ''
      }
    },
    computed: mapState({
      user: state => state.user.user,
      error: state => state.user.error,
      loading: state => state.user.loading
    }),
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },
    methods: {
      onSignin () {
        this.$store.dispatch('user/signUserIn', {email: this.email, password: this.password})
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>
