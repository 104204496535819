import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'


var firebaseConfig = {
  apiKey: "AIzaSyCxtEkKIUnd4jtbXooCiKvJVYtQEwsVSf8",
  authDomain: "breuer-webservice.firebaseapp.com",
  projectId: "breuer-webservice",
  storageBucket: "breuer-webservice.appspot.com",
  messagingSenderId: "354659699281",
  appId: "1:354659699281:web:45a033f3857664d4d7c670",
  measurementId: "G-9WL4RN5DTW"
}

// Initialisieren Sie Firebase
firebase.initializeApp(firebaseConfig)

export default firebase