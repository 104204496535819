import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  angebote: [],
  loading: false,
  error: null
}

// getters
const getters = {
  angebot: (state) => (id) => {
    return state.angebote.find(angebot => angebot.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadAngebote ({commit}) {
    commit('setLoading', true)
    console.log('Angebote laden...')
    firebase.firestore().collection('angebote').orderBy('angebotsnummer', 'desc')
    .onSnapshot(snapshot => {
      const angebote = []
      snapshot.forEach(doc => {
        angebote.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedAngebote', angebote)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createAngebot ({commit, getters}, payload) {
    firebase.firestore().collection('angebote').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteAngebotData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('angebote').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteAngebot', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateAngebotData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('angebote').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateAngebot', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedAngebote (state, payload) {
    state.angebote = payload
  },
  createAngebot (state, payload) {
    state.angebote.push(payload)
  },
  updateAngebot (state, payload) {
    const angebot = state.angebote.find(angebot => {
      return angebot.id === payload.id
    })
    if (payload.creatorID) {
      angebot.creatorID = payload.creatorID
    }
    if (payload.date) {
      angebot.date = payload.date
    }
    if (payload.bezahlt) {
      angebot.bezahlt = payload.bezahlt
    }
    if (payload.fahrer) {
      angebot.fahrer = payload.fahrer
    }
    if (payload.km) {
      angebot.km = payload.km
    }
    if (payload.title) {
      angebot.title = payload.title
    }
  },
  deleteAngebot (state, payload) {
    let array = state.angebote
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.angebote = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
