<template>
  <v-container fluid>
    <v-app-bar class="red">
      Test
    </v-app-bar>
    <v-sheet height="90vh" width="100vw">
      <v-container fluid class="d-flex align-center">
        <v-card class="rounded-lg" outlined width="calc(100% / 7)"><v-img :src="require('@/assets/img/korpodesk/1.png')"></v-img></v-card>
        <v-card class="rounded-lg" outlined width="calc(100% / 7)"><v-img :src="require('@/assets/img/korpodesk/2.png')"></v-img></v-card>
        <v-card class="rounded-lg" outlined width="calc(100% / 7)"><v-img :src="require('@/assets/img/korpodesk/3.png')"></v-img></v-card>
        <v-card class="rounded-lg" outlined width="calc(100% / 7)"><v-img :src="require('@/assets/img/korpodesk/4.png')"></v-img></v-card>
        <v-card class="rounded-lg" outlined width="calc(100% / 7)"><v-img :src="require('@/assets/img/korpodesk/5.png')"></v-img></v-card>
        <v-card class="rounded-lg" outlined width="calc(100% / 7)"><v-img :src="require('@/assets/img/korpodesk/6.png')"></v-img></v-card>
        <v-card class="rounded-lg" outlined width="calc(100% / 7)"><v-img :src="require('@/assets/img/korpodesk/7.png')"></v-img></v-card>
      </v-container>
    </v-sheet>
    <v-sheet height="100vh" width="100vw" class="mt-n16 d-flex justify-center">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-img class="white--text align-end" height="240px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
                <v-card-title>Bewerbungsportal German SDG & UNIDO Innovation Award</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                <v-chip label small class="mr-2">Webanwendung</v-chip>
                <v-chip label small class="mr-2">Vue.js</v-chip>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text block>Projekt ansehen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-img class="white--text align-end" height="240px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
                <v-card-title>Vereinsverwaltung Korpodesk</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                <v-chip label small class="mr-2">Konzeption</v-chip>
                <v-chip label small class="mr-2">UI-/UX-Design</v-chip>
                <v-chip label small class="mr-2">Umsetzung</v-chip>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text block to="/korpodesk">Projekt ansehen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-img class="white--text align-end" height="240px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
                <v-card-title>Webseite Senat der Wirtschaft e.V.</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                <v-chip label small class="mr-2">Relaunch Webseite</v-chip>
                <v-chip label small class="mr-2">Wordpress</v-chip>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text block>Projekt ansehen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      showNeueFahrt: false
    }
  },
  computed: {
    test2 () {
      return 'test'
    }
  },
  methods: {
    test () {
      console.log('test')
    }
  }
}
</script>

<style>
</style>
