<template>
  <v-container fluid>
    <v-sheet height="100vh" width="100vw" class="d-flex align-center justify-center">
      <v-card class="rounded-lg pa-12" outlined width="600px">
        <v-card-title class="justify-space-around">
          <v-avatar
          color="primary"
          size="156"
        >
          <v-img src="https://benediktbreuer.de/wp-content/uploads/2022/03/Benedikt-1-scaled-e1647784126860-2048x2048.jpg"></v-img>
        </v-avatar>
          Benedikt Breuer<br>
          Web-Entwicklung | Webdesign | UX-Design
        </v-card-title>

        
        <v-divider></v-divider>
        <v-card-text>
          Benedikt Breuer
        </v-card-text>
        <v-card-actions>
          Telefon Handy...
        </v-card-actions>
      </v-card>
      <!-- <v-parallax height="100%" src="https://cdn.vuetifyjs.com/images/parallax/material.jpg">
        <v-row class="mt-12">
          <v-col cols="12" md="6" offset-md="3" class="d-flex align-center">
            
          </v-col>
        </v-row>
      </v-parallax> -->
    </v-sheet>
    <v-sheet height="100vh" width="100vw" class="mt-n16 d-flex justify-center">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-img class="white--text align-end" height="240px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
                <v-card-title>Bewerbungsportal German SDG & UNIDO Innovation Award</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                <v-chip label small class="mr-2">Webanwendung</v-chip>
                <v-chip label small class="mr-2">Vue.js</v-chip>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text block>Projekt ansehen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-img class="white--text align-end" height="240px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
                <v-card-title>Vereinsverwaltung Korpodesk</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                <v-chip label small class="mr-2">Konzeption</v-chip>
                <v-chip label small class="mr-2">UI-/UX-Design</v-chip>
                <v-chip label small class="mr-2">Umsetzung</v-chip>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text block to="/korpodesk">Projekt ansehen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-img class="white--text align-end" height="240px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
                <v-card-title>Webseite Senat der Wirtschaft e.V.</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                <v-chip label small class="mr-2">Relaunch Webseite</v-chip>
                <v-chip label small class="mr-2">Wordpress</v-chip>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn text block>Projekt ansehen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      showNeueFahrt: false
    }
  },
  computed: {
    test2 () {
      return 'test'
    }
  },
  methods: {
    test () {
      console.log('test')
    }
  }
}
</script>

<style>
</style>
