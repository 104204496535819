import Vue from 'vue'
import Vuex from 'vuex'
import rechnungen from './modules/rechnungen'
import vertrage from './modules/vertrage'
import angebote from './modules/angebote'
import zeiten from './modules/zeiten'
import user from './modules/user'
import kunden from './modules/kunden'
import webseiten from './modules/webseiten'
import projekte from './modules/projekte'
import aquisen from './modules/aquisen'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    user,
    rechnungen,
    vertrage,
    angebote,
    zeiten,
    kunden,
    webseiten,
    projekte,
    aquisen
  }
})
