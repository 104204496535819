<template>
  <v-container>
    <v-row v-if="error"  no-gutters>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-alert class="warning">
          {{ error.message }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card outlined class="ma-2 pa-4">
          <v-card-title>
            <v-spacer></v-spacer>
            Login
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" >
                <v-text-field outlined dense name="name" label="Name" id="name" v-model="name" type="text" required></v-text-field>
              </v-col>
              <v-col cols="12" >
                <v-text-field outlined dense name="email" label="E-Mail" id="email" v-model="email" type="email" required></v-text-field>
              </v-col>
              <v-col cols="12" >
                <v-text-field outlined dense name="password" label="Passwort" id="password" v-model="password" type="password" required></v-text-field>
              </v-col>
              <v-col cols="12" >
                <v-text-field outlined dense name="confirmPassword" label="Passwort wiederholen" id="confirmPassword" v-model="confirmPassword" type="password" :rules="[comparePasswords]"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text to="/anmelden">Anmelden</v-btn>
            <v-spacer></v-spacer>
            <v-btn text class="primary" @click="onSignup()">Registrieren</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      }
    },
    computed: {
      comparePasswords () {
        return this.password !== this.confirmPassword ? 'Passwörter sind nicht gleich' : ''
      },
      user () {
        return this.$store.state.user.user
      },
      error () {
        return this.$store.state.user.error
      },
      loading () {
        return this.$store.state.user.loading
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },
    methods: {
      onSignup () {
        this.$store.dispatch('user/signUserUp', {name: this.name, email: this.email, password: this.password})
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>
