<template>
  <v-container fluid>
    

    <v-row class="pa-2">
      <v-col cols="10" offset="1">
        <v-card outlined>
          <v-card-title>
            Master Mind
            <v-spacer></v-spacer>
            <v-btn @click="hideTips = !hideTips">Tips: {{ !hideTips }}</v-btn>
            <v-btn class="mr-2" outlined @click="resetGame()">Neues Spiel</v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="tip[0] === undefined">
            <v-btn class="mr-2" outlined @click="resetGame()">Neues Spiel</v-btn>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col cols="6" offset="3" class="d-flex align-center justify-space-around">
                <v-btn fab :disabled="!succes" v-for="(item, index) in tip" :key="index" :class="getFarben(item)"></v-btn>
                <v-btn fab text disabled></v-btn>
              </v-col>
              <v-col cols="3">
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row v-for="(item, index) in antworten" :key="index">
              <v-col cols="3" class="d-flex align-center justify-center">
                <span class="title mr-4">Runde {{ index + 1 }}</span>
              </v-col>
              <v-col cols="6" class="d-flex align-center justify-space-around">
                <v-menu v-for="(item2, index2) in item" :key="index2" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn fab :color="getFarben(item2)" v-bind="attrs" v-on="on">
                      {{ item2 }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="i in 7" :key="i" link :class="getFarben(i)" @click="changeValue(i, index, index2)">
                      <v-list-item-title>{{ i }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn fab text @click="testRound(index)"><v-icon>send</v-icon></v-btn>
              </v-col>
              <v-col cols="3" class="d-flex align-center justify-right">
                <v-btn fab x-small outlined v-for="(item2, index2) in feedback[index]" :key="index2" class="ml-1" disabled :class="getFeedbackClass(item2)">
                  <v-icon v-if="isInTipp(feedback[index], index)">check</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="succes">
          <v-card-title>
            <v-spacer></v-spacer>
            Geschafft! Du hast {{ antworten.length }} Runden gebraucht!
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" outlined @click="resetGame()">Neues Spiel</v-btn>
            <v-spacer></v-spacer>
          </v-card-title>
        </v-card>
      </v-col>
      <!-- <v-col cols="2">
        <v-card outlined>
          <v-card-title>Einstellungen</v-card-title>
          Succes <br> {{ succes }} <br> <br>
          Tip <br> {{ tip }} <br> <br>
          Antworten <br> {{ antworten }}<br> <br>
          Feedback <br> {{ feedback }} <br>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>


<script>
  export default {
    data () {
      return {
        succes: false,
        tip: [],
        antworten: [],
        feedback: [],
        hideTips: true
      }
    },
    beforeMount () {
      this.resetGame()
    },
    computed: {
      eins () {
        return ''
      }
    },
    methods: {
      resetGame () {
        console.log('Reset Game')
        for (let i = 0; i < 5; i++) {
          this.tip[i] = Math.floor(Math.random() * 7) + 1;
        }
        this.antworten = [[0, 0, 0, 0, 0]]
        this.feedback = []
        this.succes = false
      },
      testRound (round) {
        let tipKopie = [...this.tip]
        let ergebnis = [0, 0, 0, 0, 0]
        for (let i = 0; i < 5; i++) {
          if (this.antworten[round][i] === tipKopie[i]) {
            ergebnis[i] = 2
            tipKopie[i] = -1
          }
        }
        for (let i = 0; i < 5; i++) {
          if (ergebnis[i] !== 2) {
            let indexInTip = tipKopie.indexOf(this.antworten[round][i])
            if (indexInTip !== -1 && tipKopie[indexInTip] !== -1) {
              ergebnis[i] = 1
              tipKopie[indexInTip] = -1
            }
          }
        }
        if (ergebnis.toString() === [2, 2, 2, 2, 2].toString()) {
          this.succes = true
          this.feedback.push(ergebnis.sort((a, b) => b - a))
        } else {
          this.feedback.push(ergebnis.sort((a, b) => b - a))
          this.antworten.push([0, 0, 0, 0, 0])
        }
      },
      isInTipp (feedback, row) {
        let array1 = this.antworten[row]
        let array2 = this.antworten[this.antworten.length - 1]

        let ergebnisArray = [];
        const bereitsVerglichen = new Set();

        for (let i = 0; i < array1.length; i++) {
          if (array1[i] === array2[i]) {
            ergebnisArray.push(2); // Gleiche Zahl an der gleichen Stelle
          } else if (
            (!bereitsVerglichen.has(array2[i]) && array1.includes(array2[i])) ||
            (!bereitsVerglichen.has(array1[i]) && array2.includes(array1[i]))
          ) {
            bereitsVerglichen.add(array1[i]);
            bereitsVerglichen.add(array2[i]);
            ergebnisArray.push(1); // Gleiche Zahl an einer anderen Stelle
          } else {
            ergebnisArray.push(0); // Keine Übereinstimmung
          }
        }
        ergebnisArray = ergebnisArray.sort((a, b) => b - a)
        if (this.hideTips) {
          return false
        } else {
          console.log(ergebnisArray)
          console.log(feedback)
          if (ergebnisArray.toString() === feedback.toString()) {
            console.log('true')
            return true
          } else {
            return false
          }
        }
      },
      getFarben (index) {
        switch (index) {
          case 1: return 'red';
          case 2: return 'pink';
          case 3: return 'purple';
          case 4: return 'blue';
          case 5: return 'green';
          case 6: return 'lime';
          case 7: return 'amber';
          default:
            return '';
        }
      },
      getFeedbackClass (index) {
        switch (index) {
          case 0:
            return '';
          case 1:
            return 'orange';
          case 2:
            return 'green';
          default:
            return '';
        }
      },
      changeValue (i, x, y) {
        this.$set(this.antworten[x], y, i)
      }
    }
  }
</script>

<style>
.loader{
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
