import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  kunden: [],
  loading: false,
  error: null
}

// getters
const getters = {
  kunde: (state) => (id) => {
    return state.kunden.find(kunde => kunde.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadKunden ({commit}) {
    commit('setLoading', true)
    console.log('Kunden laden...')
    firebase.firestore().collection('kunden').orderBy('firma', 'asc')
    .onSnapshot(snapshot => {
      const kunden = []
      snapshot.forEach(doc => {
        kunden.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedKunden', kunden)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createKunde ({commit, getters}, payload) {
    firebase.firestore().collection('kunden').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteKundeData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('kunden').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteKunde', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateKundeData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('kunden').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateKunde', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedKunden (state, payload) {
    state.kunden = payload
  },
  createKunde (state, payload) {
    state.kunden.push(payload)
  },
  updateKunde (state, payload) {
    const kunde = state.news.find(kunde => {
      return kunde.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        kunde[key] = payload[key]
      }
    })
  },
  deleteKunde (state, payload) {
    let array = state.kunden
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.kunden = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
