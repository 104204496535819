import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  projekte: [],
  loading: false,
  error: null
}

// getters
const getters = {
  projekt: (state) => (id) => {
    return state.projekte.find(projekt => projekt.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadProjekte ({commit}) {
    commit('setLoading', true)
    console.log('Projekte laden...')
    firebase.firestore().collection('projekte')
    .onSnapshot(snapshot => {
      const projekte = []
      snapshot.forEach(doc => {
        projekte.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedProjekte', projekte)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createProjekt ({commit, getters}, payload) {
    firebase.firestore().collection('projekte').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteProjektData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('projekte').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteProjekt', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateProjektData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('projekte').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateProjekt', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedProjekte (state, payload) {
    state.projekte = payload
  },
  createProjekt (state, payload) {
    state.projekte.push(payload)
  },
  updateProjekt (state, payload) {
    const projekt = state.projekte.find(projekt => {
      return projekt.id === payload.id
    })
    if (payload.creatorID) {
      projekt.creatorID = payload.creatorID
    }
    if (payload.date) {
      projekt.date = payload.date
    }
    if (payload.bezahlt) {
      projekt.bezahlt = payload.bezahlt
    }
    if (payload.fahrer) {
      projekt.fahrer = payload.fahrer
    }
    if (payload.km) {
      projekt.km = payload.km
    }
    if (payload.title) {
      projekt.title = payload.title
    }
  },
  deleteProjekt (state, payload) {
    let array = state.projekte
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.projekte = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
