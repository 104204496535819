export default (value, projekte) => {
  let projekttext = ''
  if (value !== undefined) {
    let projekt = projekte.find(projekt => projekt.id === value)
    if (projekt) {
      projekttext = projekt.titel
    } else {
      projekttext = value + ' (Kein Projekt gefunden)'
    }
  }
  return projekttext
}
