<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator='{ on }'>
              <v-btn v-on='on' icon @click="neueAquiseErstellen()"><v-icon>add</v-icon></v-btn>
            </template>
            <span>Neue Aquise</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model='search'
            append-icon='search'
            label='Suchen'
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-data-table
            dense
            :headers="headers"
            :items="aquisen"
            :search="search"
            height="calc(100vh - 270px)"
            fixed-header
            :items-per-page="100"
            :footer-props="{
              'items-per-page-text': 'Zeilen pro Seite',
              'items-per-page-all-text': 'Alle',
              'items-per-page-options': [100, 250, -1]
            }"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Aquisen vorhanden"
          >
            <template v-slot:item="{ item }">
              <tr v-on:dblclick="(showAquise = true) && (selectedAquise = item) && (neueAquise = false)">
                <td>{{ item.log[item.log.length - 1].date.seconds * 1000 | date('DDMMYYYY') }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.webseite }}</td>
                <td>{{ item.firma }}</td>
                <td style="width: 20px;"><v-btn @click="(showAquise = true) && (selectedAquise = item) && (neueAquise = false)" icon><v-icon>edit</v-icon></v-btn></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showAquise"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="neueAquise">Neue Aquise</span>
          <span v-else>Aquise bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showAquise = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedAquise">
            <v-col cols="8">
              <v-row>
                <v-col cols="12" md="3"><v-select dense outlined hide-details :items="status" v-model="selectedAquise.status" label="Status"></v-select></v-col>
                <v-col cols="12" md="9"><v-text-field dense outlined hide-details v-model="selectedAquise.webseite" label="Webseite"></v-text-field></v-col>
                <v-col cols="12" md="12"><v-text-field dense outlined hide-details v-model="selectedAquise.firma" label="Firma"></v-text-field></v-col>
                <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedAquise.anrede" label="Anrede"></v-text-field></v-col>
                <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedAquise.titel" label="Titel"></v-text-field></v-col>
                <v-col cols="12" md="4"><v-text-field dense outlined hide-details v-model="selectedAquise.vorname" label="Vorname"></v-text-field></v-col>
                <v-col cols="12" md="4"><v-text-field dense outlined hide-details v-model="selectedAquise.nachname" label="Nachname"></v-text-field></v-col>
                <v-col cols="12" md="12"><v-text-field dense outlined hide-details v-model="selectedAquise.mail" label="Mail"></v-text-field></v-col>
                <v-col cols="12" md="12"><v-textarea dense outlined hide-details v-model="selectedAquise.anmerkung" label="Anmerkung"></v-textarea></v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-card-title>Log:</v-card-title>
              <v-simple-table dense height="300px" fixed-header>
                <tbody>
                  <tr v-for="(item, index) in selectedAquise.log" :key="index" class="pa-2">
                    <td style="width: 20px;">{{ index + 1 }}.</td>
                    <td>{{ item.date.seconds * 1000 | date('DDMMYYYY') }}</td>
                    <td>{{ item.status }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="red--text" icon @click="deleteAquise(selectedAquise.id)"><v-icon>delete</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showAquise = false">Abbrechen</v-btn>
          <v-btn class="primary" v-if="neueAquise" @click="createAquise()" dark text>Erstellen</v-btn>
          <v-btn class="primary" v-else @click="updateAquise()" dark text>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
  export default {
    data () {
      return {
        search: '',
        showAquise: false,
        neueAquise: false,
        selectedAquise: {},
        headers: [
          { text: 'Letzte Aktualisierung', value: '', sortable: false },
          { text: 'Status', value: 'status' },
          { text: 'Webseite', value: 'webseite' },
          { text: 'Firma', value: 'firma' },
          { text: '', value: '', sortable: false }
        ],
        status: ['Entwurf', 'Angefragt', 'Nachgefragt 1', 'Nachgefragt 2', 'In Verhandlung', 'Angenommen', 'Vertagt', 'Abgelehnt', 'Unbeantwortet']
      }
    },
    computed: {
      aquisen () {
        return this.$store.state.aquisen.aquisen
      },
      projekte () {
        return this.$store.state.projekte.projekte
      },
    },
    methods: {
      createAquise () {
        this.selectedAquise.log.push({
          date: new Date(),
          status: this.selectedAquise.status
        })
        this.$store.dispatch('aquisen/createAquise', this.selectedAquise)
        this.showAquise = false
        this.selectedAquise = {}
      },
      duplicateAquise (item) {
        this.selectedAquise = item
        this.selectedAquise.log = []
        this.neueAquise = true
        this.showAquise = true
      },
      updateAquise () {
        this.selectedAquise.log.push({
          date: new Date(),
          status: this.selectedAquise.status
        })
        this.$store.dispatch('aquisen/updateAquiseData', this.selectedAquise)
        this.showAquise = false
        this.selectedAquise = {}
      },
      deleteAquise (id) {
        this.$store.dispatch('aquisen/deleteAquiseData', id)
        this.showAquise = false
        this.selectedAquise = {}
      },
      neueAquiseErstellen () {
        this.selectedAquise = {}
        this.selectedAquise.log = []
        this.neueAquise = true
        this.showAquise = true
      },
    }
  }
</script>

<style>
.loader{
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
