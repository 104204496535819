import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  rechnungen: [],
  loading: false,
  error: null
}

// getters
const getters = {
  rechnung: (state) => (id) => {
    return state.rechnungen.find(rechnung => rechnung.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadRechnungen ({commit}) {
    commit('setLoading', true)
    console.log('Rechnungen laden...')
    firebase.firestore().collection('rechnungen').orderBy('rechnungsnummer', 'desc')
    .onSnapshot(snapshot => {
      const rechnungen = []
      snapshot.forEach(doc => {
        rechnungen.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedRechnungen', rechnungen)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createRechnung ({commit, getters}, payload) {
    firebase.firestore().collection('rechnungen').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteRechnungData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('rechnungen').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteRechnung', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateRechnungData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('rechnungen').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateRechnung', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedRechnungen (state, payload) {
    state.rechnungen = payload
  },
  createRechnung (state, payload) {
    state.rechnungen.push(payload)
  },
  updateRechnung (state, payload) {
    const rechnung = state.rechnungen.find(rechnung => {
      return rechnung.id === payload.id
    })
    if (payload.creatorID) {
      rechnung.creatorID = payload.creatorID
    }
    if (payload.date) {
      rechnung.date = payload.date
    }
    if (payload.bezahlt) {
      rechnung.bezahlt = payload.bezahlt
    }
    if (payload.fahrer) {
      rechnung.fahrer = payload.fahrer
    }
    if (payload.km) {
      rechnung.km = payload.km
    }
    if (payload.title) {
      rechnung.title = payload.title
    }
  },
  deleteRechnung (state, payload) {
    let array = state.rechnungen
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.rechnungen = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
