<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator='{ on }'>
              <v-btn v-on='on' icon @click="(showProjekt = true) && (neuerProjekt = true) && (selectedProjekt = {})"><v-icon>add</v-icon></v-btn>
            </template>
            <span>Neues Projekt</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model='search'
            append-icon='search'
            label='Suchen'
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="projekte"
            :search="search"
            dense
            height="calc(100vh - 220px)"
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            no-data-text="Keine Projekte vorhanden"
          >
            <template v-slot:item="{ item, index }">
              <tr v-on:dblclick="(showProjekt = true) && (selectedProjekt = item) && (neuerProjekt = false)">
                <td style="width: 20px;">{{ index + 1 }}.</td>
                <td>{{ item.titel }}</td>
                <td>{{ item.kunde | user(kunden) }}</td>
                <td style="width: 130px;">{{ item.start | date('DDMMYYYY') }}</td>
                <td v-if="item.status === 'Entwurf'"><v-chip small color="grey" label>{{ item.status }}</v-chip></td>
                <td v-if="item.status === 'Offen'"><v-chip small color="green" label>{{ item.status }}</v-chip></td>
                <td v-if="item.status === 'Abgeschlossen'"><v-chip small color="primary" label>{{ item.status }}</v-chip></td>
                <td style="width: 140px;" class="text-right">{{ item.budget | money }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showProjekt"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          {{ neuerProjekt ? 'Neuen Projekt anlegen' : ('Projekt bearbeiten | ' + selectedProjekt.titel ) }}
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showProjekt = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedProjekt">
            <v-col cols="12" md="6"><v-text-field dense outlined hide-details v-model="selectedProjekt.titel" label="Titel"></v-text-field></v-col>
            <v-col cols="12" md="3"><v-autocomplete dense outlined hide-details :items="kundenSelect" v-model="selectedProjekt.kunde" label="Kunde"></v-autocomplete></v-col>
            <v-col cols="12" md="3"><v-autocomplete dense outlined hide-details :items="['Entwurf', 'Offen', 'Abgeschlossen']" v-model="selectedProjekt.status" label="Status"></v-autocomplete></v-col>
            <v-col cols="12" md="3"><v-text-field dense outlined hide-details v-model="selectedProjekt.budget" label="Budget"></v-text-field></v-col>
            <v-col cols="12" md="3"><v-text-field dense outlined hide-details v-model="selectedProjekt.start" label="Start"></v-text-field></v-col>
            <v-col cols="12" md="3"><v-text-field dense outlined hide-details v-model="selectedProjekt.ende" label="Ende"></v-text-field></v-col>
            <v-col cols="12" md="3"><v-text-field dense outlined hide-details v-model="selectedProjekt.stundenlohn" label="Stundenlohn"></v-text-field></v-col>
            <v-col cols="12" md="12"><v-textarea dense outlined hide-details v-model="selectedProjekt.bemerkung" label="Bemerkung"></v-textarea></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12" md="8">
              <apexChart height="192"  type="bar" :options="options" :series="zeiten"></apexChart>
              <v-progress-linear
                :value="zeiten[0].sum * selectedProjekt.stundenlohn / selectedProjekt.budget * 100"
                color="primary"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ zeiten[0].sum }} von {{ (selectedProjekt.budget / selectedProjekt.stundenlohn).toFixed(1) }} Stunden ({{ Math.ceil(value).toFixed(1) }} %)</strong>
                </template>
              </v-progress-linear>
            </v-col>
            <v-col cols="12" md="4">
              <v-simple-table dense fixed-header height="250">
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th class="text-right">Dauer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in zeiten[0].zeiten" :key="index">
                    <td style="width: 130px;">{{ item.datum | date('DDMMYYYY') }}</td> 
                    <td style="width: 100px;" class="text-right">{{ item.dauer.toFixed(2) }} h</td>
                    <td style="width: 20px;" v-if="item.abgerechnet" @click="toggleAbgerechnet(item)"><v-icon>check_circle</v-icon></td>
                    <td style="width: 20px;" v-else @click="toggleAbgerechnet(item)"><v-icon>radio_button_unchecked</v-icon></td>
                  </tr>
                </tbody>
              </v-simple-table>  
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn v-if="!neuerProjekt" class="red--text" icon @click="deleteProjekt(selectedProjekt.id)"><v-icon>delete</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showProjekt = false">Abbrechen</v-btn>
          <v-btn v-if="!neuerProjekt" class="primary" @click="updateProjekt()" dark text>Update</v-btn>
          <v-btn v-else class="primary" @click="createProjekt()" dark text>Anlegen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      showProjekt: false,
      neuerProjekt: false,
      selectedProjekt: {},
      headers: [
        { text: '', value: '' },
        { text: 'Projekt', value: 'titel' },
        { text: 'Kunde', value: 'nachname' },
        { text: 'Start', value: 'start' },
        { text: 'Status', value: 'status' },
        { text: 'Budget', value: 'budget' }
      ],
      jahr: new Date().getFullYear(),
      options: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'area',
          stacked: false
        },
        dataLabels: { enabled: false },
        colors: ['#36404F', '#EF5350', '#FFC107'],
        stroke: { width: 0 },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toFixed(2) + ' h'
            }
          }
        },
        yaxis: {
          min: 0,
          tickAmount: 5,
          title: { text: undefined },
          labels: {
            formatter: function (val) {
              return val.toFixed(0) + ' h'
            }
          }
        },
        xaxis: {
          title: { text: undefined }
        },
        fill: {
          colors: ['#36404F', '#EF5350', '#FFC107'],
          opacity: 1,
          type: 'solid'
        },
        legend: { show: false }
      }
    }
  },
  computed: {
    projekte() {
      const projekte = this.$store.state.projekte.projekte
      projekte.sort((a, b) => {
        const statusOrder = {
          Entwurf: 1,
          Offen: 2,
          Abgeschlossen: 3,
        }
        return statusOrder[a.status] - statusOrder[b.status]
      })
      return projekte
    },
    kunden () {
      return this.$store.state.kunden.kunden
    },
    kundenSelect () {
      let kunden = []
      this.$store.state.kunden.kunden.forEach(kunde => {
        kunden.push({
          value: kunde.id,
          text: kunde.firma + ' (' + kunde.nachname + ', ' + kunde.vorname + ')'
        })
      })
      return kunden
    },
    zeiten () {
      // eslint-disable-next-line 
      this.options.labels = []
      let minDate = new Date().toISOString().substr(0, 10)
      let maxDate = new Date(0).toISOString().substr(0, 10)
      let zeiten = []
      this.$store.state.zeiten.zeiten.forEach(item => {
        if (item.projekt === this.selectedProjekt.id) {
          zeiten.push(item)
          if (item.datum > maxDate) {
            maxDate = item.datum
          }
          if (item.datum < minDate) {
            minDate = item.datum
          }
        }
      })
      let minJahr = parseInt(minDate.split('-')[0])
      let maxJahr = parseInt(maxDate.split('-')[0])
      let minMonat = parseInt(minDate.split('-')[1])
      let maxMonat = parseInt(maxDate.split('-')[1])
      let jahr = minJahr
      let monat = minMonat
      let monate = []
      while (jahr < maxJahr) {
        while (monat <= 12) {
          monate.push({
            jahr,
            monat: monat,
            dauer: 0
          })
          monat++
        }
        monat = 1
        jahr++
      }
      while (monat <= maxMonat) {
        monate.push({
          jahr,
          monat: monat,
          dauer: 0
        })
        monat++
      }
      let data = [{
        name: 'Dauer',
        data: [],
        sum: 0,
        zeiten
      }]
      zeiten.forEach(item => {
        let datum = item.datum.split('-')
        let array = monate.find(monat => (parseInt(datum[0]) === parseInt(monat.jahr)) && (parseInt(datum[1]) === parseInt(monat.monat)))
        monate[monate.indexOf(array)].dauer += item.dauer
      })
      let max = 0
      let sum = 0
      monate.forEach(monat => {
        data[0].data.push(monat.dauer)
        this.options.labels.push(monat.monat + '. ' + monat.jahr)
        if (monat.dauer > max) { max = monat.dauer }
        sum += parseFloat(monat.dauer)
      })
      data[0].sum = sum
      // eslint-disable-next-line 
      this.options.yaxis.max = Math.ceil(max / Math.pow(10, parseInt(max).toString().length - 1)) * Math.pow(10, parseInt(max).toString().length - 1)
      return data
    }
  },
  methods: {
    toggleAbgerechnet (item) {
      item.abgerechnet = !item.abgerechnet
      this.$store.dispatch('zeiten/updateZeitData', item)
    },
    createProjekt () {
      this.$store.dispatch('projekte/createProjekt', this.selectedProjekt)
      this.showProjekt = false
      this.selectedProjekt = {}
    },
    updateProjekt () {
      this.$store.dispatch('projekte/updateProjektData', this.selectedProjekt)
      this.showProjekt = false
      this.selectedProjekt = {}
    },
    deleteProjekt (id) {
      this.$store.dispatch('projekte/deleteProjektData', id)
      this.showProjekt = false
      this.selectedProjekt = {}
    },
    openLinkInNewTab (url) {
      if (url !== undefined) {
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style>
.loader{
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
