import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  aquisen: [],
  loading: false,
  error: null
}

// getters
const getters = {
  aquise: (state) => (id) => {
    return state.aquisen.find(aquise => aquise.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadAquisen ({commit}) {
    commit('setLoading', true)
    console.log('Aquisen laden...')
    firebase.firestore().collection('aquisen')
    .onSnapshot(snapshot => {
      const aquisen = []
      snapshot.forEach(doc => {
        aquisen.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedAquisen', aquisen)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createAquise ({commit, getters}, payload) {
    firebase.firestore().collection('aquisen').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteAquiseData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('aquisen').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteAquise', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateAquiseData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('aquisen').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateAquise', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedAquisen (state, payload) {
    state.aquisen = payload
  },
  createAquise (state, payload) {
    state.aquisen.push(payload)
  },
  updateAquise (state, payload) {
    const aquise = state.news.find(aquise => {
      return aquise.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        aquise[key] = payload[key]
      }
    })
  },
  deleteAquise (state, payload) {
    let array = state.aquisen
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.aquisen = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
