<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator='{ on }'>
              <v-btn v-on='on' icon @click="neueKundeErstellen()"><v-icon>add</v-icon></v-btn>
            </template>
            <span>Neue Kunde</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model='search'
            append-icon='search'
            label='Suchen'
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-card-title class="py-1">
            <v-tabs dense center-active centered v-model="tab">
              <v-tab v-for="(item, index) in tabs" :key="index">{{ item }}</v-tab>
            </v-tabs>
          </v-card-title>
        </v-card>
        <v-card outlined class="mt-2">
          <v-data-table
            :headers="headers"
            :items="kunden"
            :search="search"
            height="calc(100vh - 280px)"
            dense
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Kunden vorhanden"
          >
            <template v-slot:header="">
              <thead>
                <tr class="hidden-sm-and-up">
                  <th>Nr</th>
                  <th>Betrag</th>
                  <th>Status</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr v-on:dblclick="(showKunde = true) && (selectedKunde = item) && (neueKunde = false)">
                <td style="width: 100px;">{{ item.kundennummer }}</td>
                <td>{{ item.firma }}</td>
                <td>{{ item.nachname + ', ' + item.vorname }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showKunde"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="neueKunde">Neue Kunde</span>
          <span v-else>Kunde bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showKunde = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedKunde">
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedKunde.kundennummer" label="Kundennummer" append-outer-icon="refresh" @click:append-outer="getKundennummer()"></v-text-field></v-col>
            <v-col cols="12" md="10"><v-text-field dense outlined hide-details v-model="selectedKunde.firma" label="Firma"></v-text-field></v-col>
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedKunde.anrede" label="Anrede"></v-text-field></v-col>
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedKunde.titel" label="Titel"></v-text-field></v-col>
            <v-col cols="12" md="4"><v-text-field dense outlined hide-details v-model="selectedKunde.vorname" label="Vorname"></v-text-field></v-col>
            <v-col cols="12" md="4"><v-text-field dense outlined hide-details v-model="selectedKunde.nachname" label="Nachname"></v-text-field></v-col>
            <v-col cols="12" md="6"><v-text-field dense outlined hide-details v-model="selectedKunde.adresse" label="Adresse"></v-text-field></v-col>
            <v-col cols="12" md="3"><v-text-field dense outlined hide-details v-model="selectedKunde.plz" label="PLZ"></v-text-field></v-col>
            <v-col cols="12" md="3"><v-text-field dense outlined hide-details v-model="selectedKunde.ort" label="Ort"></v-text-field></v-col>
            <v-col cols="12" md="6"><v-text-field dense outlined hide-details v-model="selectedKunde.telefon" label="Telefon"></v-text-field></v-col>
            <v-col cols="12" md="6"><v-text-field dense outlined hide-details v-model="selectedKunde.mail" label="Mail"></v-text-field></v-col>
            <v-col cols="12" md="3"><v-text-field dense outlined hide-details v-model="selectedKunde.stundenlohn" label="Standard Stundenlohn"></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-simple-table dense height="300px" fixed-header>
                <tbody>
                  <tr class="font-weight-bold">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">Summe (ohne MwSt.) {{ rechnungen.reduce((sum, item) => sum + parseFloat(item.summe), 0) | money }}</td>
                  </tr>
                  <tr v-for="(item, index) in rechnungen" :key="index" class="pa-2">
                    <td style="width: 20px;">{{ index + 1 }}.</td>
                    <td>{{ item.rechnungsnummer }}</td>
                    <td>{{ item.rechnungsdatum | date('DDMMYYYY') }}</td>
                    <td class="text-right"><span v-if="item.mwst" class="grey--text mr-2">{{ item.summe * 1.19 | money }}</span> {{ item.summe | money }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-></v->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="red--text" icon @click="deleteKunde(selectedKunde.id)"><v-icon>delete</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showKunde = false">Abbrechen</v-btn>
          <v-btn class="primary" v-if="neueKunde" @click="createKunde()" dark text>Erstellen</v-btn>
          <v-btn class="primary" v-else @click="updateKunde()" dark text>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
  export default {
    data () {
      return {
        search: '',
        showKunde: false,
        neueKunde: false,
        selectedKunde: {},
        tab: 0,
        tabs: [],
        headers: [
          { text: 'Nummer', value: 'kundennummer' },
          { text: 'Firma', value: 'firma' },
          { text: 'Name', value: 'nachname' }
        ]
      }
    },
    computed: {
      kunden () {
        return this.$store.state.kunden.kunden
      },
      rechnungen () {
        if (this.selectedKunde) {
          return this.$store.state.rechnungen.rechnungen.filter(item => item.kunde === this.selectedKunde.id)
        } else {
          return this.$store.state.rechnungen.rechnungen
        }
      },
      kundenSelect () {
        let kunden = []
        this.$store.state.kunden.kunden.forEach(kunde => {
          kunden.push({
            value: kunde.id,
            text: kunde.firma + '(' + kunde.nachname + ', ' + kunde.vorname + ')'
          })
        })
        return kunden
      }
    },
    methods: {
      neueKundeErstellen () {
        this.selectedKunde = {
          kundennummer: this.getKundennummer()
        }
        this.neueKunde = true
        this.showKunde = true
      },
      getKundennummer () {
        const kundennummern = this.kunden.map(r => parseInt(r.kundennummer))
        const hoechsteNummer = kundennummern.reduce((max, num) => Math.max(max, num), 0)
        this.selectedKunde.kundennummer = `${(hoechsteNummer + 1).toString().padStart(3, '0')}`
        return `${(hoechsteNummer + 1).toString().padStart(3, '0')}`
      },
      createKunde () {
        this.$store.dispatch('kunden/createKunde', this.selectedKunde)
        this.showKunde = false
        this.selectedKunde = {}
      },
      duplicateKunde (item) {
        this.selectedKunde = item
        this.neueKunde = true
        this.showKunde = true
      },
      updateKunde () {
        this.$store.dispatch('kunden/updateKundeData', this.selectedKunde)
        this.showKunde = false
        this.selectedKunde = {}
      },
      deleteKunde (id) {
        this.$store.dispatch('kunden/deleteKundeData', id)
        this.showKunde = false
        this.selectedKunde = {}
      }
    }
  }
</script>

<style>
.loader{
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
