import { store } from '../store'

export default (to, from, next) => {
  if (store.state.user.user) {
    next()
  } else {
    // next()
    next('/anmelden')
  }
}
