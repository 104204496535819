<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters class="d-flex align-center">
        <v-col cols="9" class="d-flex align-center">
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-card-title>
            {{ wochenstunden.mondayOfTargetWeek | date('DD') }}. - {{ wochenstunden.sundayOfTargetWeek | date('DDMM') }}
            |
            {{ wochenstunden.sumDauer.toFixed(2).replace('.', ',') }} h |
            {{ wochenstunden.sumEinnahmen | money }}</v-card-title>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-text-field v-model='search' append-icon='search' label='Suchen' single-line solo flat hide-details
            clearable clear-icon="close"></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn @click="changeFilter()" icon>
            <v-icon>{{ filter ? 'filter_alt' : 'filter_alt_off' }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-container>
      <v-row class="mt-1">
        <v-col cols="12" lg="7" offset-lg="0">
          <v-sheet height="calc(80vh - 100px)" rounded outlined class="pa-2">
            <v-calendar :weekdays="[1, 2, 3, 4, 5, 6, 0]" locale="de" ref="calendar" v-model="value" color="primary"
              type="week" :events="events" :event-color="getEventColor" :event-ripple="false"
              @mousedown:event="startDrag" @mousedown:time="startTime" @mousemove:time="mouseMove"
              @mouseup:time="endDrag" @mouseleave.native="cancelDrag">
              <template v-slot:event="{ event, timed, eventSummary }">
                <div class="v-event-draggable">
                  <component :is="{ render: eventSummary }"></component>
                </div>
                <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="5">
          <v-card outlined class="mb-2 pa-2">
            <v-progress-linear 
              :value="item.sumdauer / wochenstunden.sumDauer * 100" 
              height="32" 
              class="mb-1 rounded-lg"
              color="blue"
              v-for="(item, key, index) in zeitenNachKunde" :key="index"
            >
              <v-row no-gutters class="mx-2">
                <v-col cols="6" class="text-truncate subtitle-2 text-left">{{ item.kundenname }}</v-col>
                <v-col cols="6" class="subtitle-2 text-right">{{ item.sumdauer.toFixed(2) }} h</v-col>
              </v-row>              
            </v-progress-linear>
          </v-card>
          <v-card outlined>
            <v-data-table dense :headers="headers" :items="zeiten" :search="search" height="calc(80vh - 300px)"
              fixed-header :items-per-page="100" :footer-props="{
            'items-per-page-text': 'Zeilen pro Seite',
            'items-per-page-all-text': 'Alle',
            'items-per-page-options': [100, 250, -1]
          }" :hide-default-header="$vuetify.breakpoint.xsOnly" no-data-text="Keine Zeiten vorhanden">
              <template v-slot:item="{ item }">
                <tr v-on:dblclick="(showZeit = true) && (selectedZeit = item) && (neueZeit = false)">
                  <td style="width: 140px;">{{ item.datum | date('DDMMYY') }}<br>{{ item.von }} - {{ item.bis }} Uhr</td>
                  <td>
                    <v-sheet style="width: 180px;" class="text-truncate font-weight-bold">{{ item.kundenname }}</v-sheet>
                    <v-sheet style="width: 180px;" class="text-truncate">{{ item.projekt | projekt(projekte) }}</v-sheet>
                  </td>
                  <td>{{ item.anmerkung }}</td>
                  <td style="width: 20px;" v-if="item.abgerechnet" @click="toggleAbgerechnet(item)">
                    <v-icon>check_circle</v-icon>
                  </td>
                  <td style="width: 20px;" v-else @click="toggleAbgerechnet(item)">
                    <v-icon>radio_button_unchecked</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showZeit" width="60%" :fullscreen="$vuetify.breakpoint.smAndDown" persistent scrollable>
      <v-card>
        <v-card-title class="primary white--text">
          <span>Zeit bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showZeit = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedZeit">
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedZeit.datum"
                label="Datum"></v-text-field></v-col>
            <v-col cols="12" md="5"><v-autocomplete dense outlined hide-details :items="kundenSelect"
                v-model="selectedZeit.kunde" label="Kunde"></v-autocomplete></v-col>
            <v-col cols="12" md="5"><v-autocomplete dense outlined hide-details :items="projekteSelect"
                v-model="selectedZeit.projekt" label="Projekt"></v-autocomplete></v-col>
            <v-col cols="12" md="4"><v-combobox dense outlined hide-details :items="titelItems" v-model="neueZeit.titel"
                label="Titel"></v-combobox></v-col>
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedZeit.von"
                label="Von"></v-text-field></v-col>
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedZeit.bis"
                label="Bis"></v-text-field></v-col>
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedZeit.dauer"
                label="Dauer"></v-text-field></v-col>
            <v-col cols="12" md="2"><v-text-field dense outlined hide-details v-model="selectedZeit.stundenlohn"
                label="Stundenlohn"></v-text-field></v-col>
            <v-col cols="12" md="12"><v-textarea dense outlined hide-details v-model="selectedZeit.anmerkung"
                label="Anmerkung"></v-textarea></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="red--text" icon @click="deleteZeit(selectedZeit.id)"><v-icon>delete</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showZeit = false">Abbrechen</v-btn>
          <v-btn class="primary" @click="updateZeit()" dark text>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
export default {
  data() {
    return {
      today: new Date().toISOString().substr(0, 10),
      search: '',
      showZeit: false,
      neueZeit: {},
      selectedZeit: {},
      headers: [
        { text: 'Datum', value: 'datum' },
        { text: 'Kunde', value: 'kundenname' },
        { text: 'Anmerkung', value: 'anmerkung' },
        { text: '', value: 'abgerechnet' }
      ],
      filter: true,
      value: new Date().toISOString().substring(0, 10),
      colors: ['#36404F'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
    }
  },
  mounted() {
    this.$refs.calendar.scrollToTime('07:30')
    this.getEvents()
  },
  computed: {
    zeiten() {
      let zeiten
      if (this.filter) {
        zeiten = this.$store.state.zeiten.zeiten.filter(item => item.abgerechnet === false)
      } else {
        zeiten = this.$store.state.zeiten.zeiten
      }
      zeiten.forEach(item => {
        const kunde = this.kunden.find(kunde => kunde.id === item.kunde);
        if (kunde) {
          item.kundenname = kunde.firma
        }
      });
      return zeiten
    },
    zeitenNachKunde () {
      let res = {}
      const targetDate = new Date(this.value);
      const targetWeekday = targetDate.getDay();
      res.mondayOfTargetWeek = new Date(targetDate);
      res.mondayOfTargetWeek.setDate(targetDate.getDate() - targetWeekday + (targetWeekday === 0 ? -6 : 1));
      res.sundayOfTargetWeek = new Date(res.mondayOfTargetWeek);
      res.sundayOfTargetWeek.setDate(res.mondayOfTargetWeek.getDate() + 6);

      // Filtere die Zeiten, deren Datum innerhalb der Zielwoche liegt
      const relevantZeiten = this.$store.state.zeiten.zeiten.filter(item => {
        const itemDate = new Date(item.datum);
        return itemDate >= res.mondayOfTargetWeek && itemDate <= res.sundayOfTargetWeek;
      });

      // Gruppiere die Zeiten nach Kunde und summiere die Dauer
      const kundenMap = relevantZeiten.reduce((acc, item) => {
        const kunde = item.kunde;
        const dauer = item.dauer;
        const kundenname = item.kundenname; // Nehmen wir an, dass das Feld "kundenname" im item existiert

        if (!acc[kunde]) {
          acc[kunde] = { kunde, sumdauer: 0, kundenname };
        }

        acc[kunde].sumdauer += dauer;
        return acc;
      }, {});

      // Wandeln das Ergebnis in ein Array von Objekten um
      const zeitenNachKundeArray = Object.values(kundenMap);

      return zeitenNachKundeArray;
    },
    wochenstunden() {
      let res = {}
      const targetDate = new Date(this.value);
      const targetWeekday = targetDate.getDay();
      res.mondayOfTargetWeek = new Date(targetDate);
      res.mondayOfTargetWeek.setDate(targetDate.getDate() - targetWeekday + (targetWeekday === 0 ? -6 : 1));
      res.sundayOfTargetWeek = new Date(res.mondayOfTargetWeek);
      res.sundayOfTargetWeek.setDate(res.mondayOfTargetWeek.getDate() + 6);
      // Filtere die Zeiten, deren Datum innerhalb der Zielwoche liegt
      const relevantZeiten = this.$store.state.zeiten.zeiten.filter(item => {
        const itemDate = new Date(item.datum);
        return itemDate >= res.mondayOfTargetWeek && itemDate <= res.sundayOfTargetWeek;
      });
      // Berechne die Summe der Dauer der relevanten Zeiten
      res.sumDauer = relevantZeiten.reduce((total, item) => total + item.dauer, 0);
      res.sumEinnahmen = 0
      relevantZeiten.forEach(item => {
        const kunde = this.$store.state.kunden.kunden.find(kunde => kunde.id === item.kunde)
        res.sumEinnahmen += kunde.stundenlohn * item.dauer
      })
      return res
    },
    kunden() {
      return this.$store.state.kunden.kunden
    },
    projekte() {
      return this.$store.state.projekte.projekte
    },
    kundenSelect() {
      let kunden = []
      this.$store.state.kunden.kunden.forEach(kunde => {
        kunden.push({
          value: kunde.id,
          text: kunde.firma + ' (' + kunde.nachname + ', ' + kunde.vorname + ')'
        })
      })
      return kunden
    },
    projekteSelect() {
      let projekte = []
      this.$store.state.projekte.projekte.forEach(projekt => {
        if (this.selectedZeit.kunde !== undefined) {
          if (projekt.kunde === this.selectedZeit.kunde) {
            projekte.push({
              value: projekt.id,
              text: projekt.titel
            })
          }
        }
      })
      return projekte
    },
    titelItems() {
      let items = []
      if (this.selectedZeit.projekt !== undefined) {
        this.$store.state.zeiten.zeiten.forEach(zeit => {
          if (zeit.projekt === this.selectedZeit.projekt) {
            items.push(zeit.titel)
          }
        })
      } else {
        this.$store.state.zeiten.zeiten.forEach(zeit => {
          if (zeit.kunde === this.selectedZeit.kunde) {
            items.push(zeit.titel)
          }
        })
      }
      return items
    },
    summe() {
      // let summe = 0
      // if (this.selectedZeit.positionen !== undefined) {
      //   this.selectedZeit.positionen.forEach(position => {
      //     summe += parseFloat(position.anzahl) * parseFloat(position.einzelpreis)
      //   })
      // }
      // this.selectedZeit.summe = summe
      // return summe
      return 'a'
    },
    events() {
      const events = []

      // const days = (max - min) / 86400000

      this.$store.state.zeiten.zeiten.forEach(item => {
        const kunde = this.kunden.find(kunde => kunde.id === item.kunde)
        const start = new Date(item.datum + ' ' + item.von).getTime()
        const end = new Date(item.datum + ' ' + item.bis).getTime()
        events.push({
          name: kunde.firma,
          color: this.rndElement(this.colors),
          start,
          end,
          item,
          timed: true
        })
      })

      return events
    },
  },
  methods: {
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedZeit = event.item
        this.selectedElement = nativeEvent.target
        setTimeout(this.showZeit = true, 10)
      }

      if (this.showZeit) {
        this.showZeit = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
      // this.selectedZeit = nativeEvent.target
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    changeFilter() {
      this.filter = !this.filter
    },
    toggleAbgerechnet(item) {
      item.abgerechnet = !item.abgerechnet
      this.selectedZeit = item
      this.updateZeit()

    },
    createZeit() {
      this.$store.dispatch('zeiten/createZeit', this.selectedZeit)
      this.showZeit = false
      this.selectedZeit = {}
    },
    updateZeit() {
      this.$store.dispatch('zeiten/updateZeitData', this.selectedZeit)
      this.showZeit = false
      this.selectedZeit = {}
    },
    deleteZeit(id) {
      this.$store.dispatch('zeiten/deleteZeitData', id)
      this.showZeit = false
      this.selectedZeit = {}
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else {
        // this.createStart = this.roundTime(mouse)
        // this.createEvent = {
        //   name: `Event #${this.events.length}`,
        //   color: this.rndElement(this.colors),
        //   start: this.createStart,
        //   end: this.createStart,
        //   timed: true,
        // }

        // this.events.push(this.createEvent)
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag() {
      let element = null
      if (this.createEvent) {
        element = this.createEvent
      }
      if (this.dragEvent) {
        element = this.dragEvent
      }
      if (element) {
        let updateEvent = element.item
        if (updateEvent) {
          updateEvent.datum = new Date(element.start).toISOString().substring(0, 10)
          updateEvent.von = new Date(element.start).toLocaleTimeString('de-De', { hour12: false, hour: 'numeric', minute: '2-digit' })
          updateEvent.bis = new Date(element.end).toLocaleTimeString('de-De', { hour12: false, hour: 'numeric', minute: '2-digit' })
          let dauer = 0
          let von = updateEvent.von.split(':')
          let bis = updateEvent.bis.split(':')
          dauer = bis[0] - von[0]
          if (von[1] === '15') { dauer = dauer - 0.25 }
          if (von[1] === '30') { dauer = dauer - 0.5 }
          if (von[1] === '45') { dauer = dauer - 0.75 }
          if (bis[1] === '15') { dauer = dauer + 0.25 }
          if (bis[1] === '30') { dauer = dauer + 0.5 }
          if (bis[1] === '45') { dauer = dauer + 0.75 }
          updateEvent.dauer = dauer
          this.selectedZeit = updateEvent
          this.updateZeit()
        } else {
          console.log(element)
          let vardatum = new Date(element.start).toISOString().substring(0, 10)
          let varvon = new Date(element.start).toLocaleTimeString('de-De', { hour12: false, hour: 'numeric', minute: '2-digit' })
          let varbis = new Date(element.end).toLocaleTimeString('de-De', { hour12: false, hour: 'numeric', minute: '2-digit' })
          let dauer = 0
          let von = varvon.split(':')
          let bis = varbis.split(':')
          dauer = bis[0] - von[0]
          if (von[1] === '15') { dauer = dauer - 0.25 }
          if (von[1] === '30') { dauer = dauer - 0.5 }
          if (von[1] === '45') { dauer = dauer - 0.75 }
          if (bis[1] === '15') { dauer = dauer + 0.25 }
          if (bis[1] === '30') { dauer = dauer + 0.5 }
          if (bis[1] === '45') { dauer = dauer + 0.75 }
          this.selectedZeit = {
            datum: vardatum,
            von: varvon,
            bis: varbis,
            dauer
          }
          this.showZeit = true
        }
      }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor(event) {
      return event === this.dragEvent
        ? `rgba(20, 20, 20, 0.7)`
        : event === this.createEvent
          ? `rgba(20, 20, 20, 0.7)`
          : event.color
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    },
  }
}
</script>

<style scoped>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
