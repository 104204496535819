export default (value, kunden) => {
  let kundetext = 'Kein Kontakt gefunden'
  let kunde = kunden.find(kunde => kunde.id === value)
  if (kunde) {
    kundetext = ''
    if (kunde.titel) {
      kundetext = kunde.titel + ' '
    }
    kundetext = kunde.firma + ' (' + kundetext + kunde.nachname + ', ' + kunde.vorname + ')'
  }
  return kundetext
}
