import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  webseiten: [],
  loading: false,
  error: null
}

// getters
const getters = {
  webseite: (state) => (id) => {
    return state.webseiten.find(webseite => webseite.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadWebseiten ({commit}) {
    commit('setLoading', true)
    console.log('Webseiten laden...')
    firebase.firestore().collection('webseiten')
    .onSnapshot(snapshot => {
      const webseiten = []
      snapshot.forEach(doc => {
        webseiten.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedWebseiten', webseiten)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createWebseite ({commit, getters}, payload) {
    firebase.firestore().collection('webseiten').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteWebseiteData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('webseiten').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteWebseite', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateWebseiteData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('webseiten').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateWebseite', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedWebseiten (state, payload) {
    state.webseiten = payload
  },
  createWebseite (state, payload) {
    state.webseiten.push(payload)
  },
  updateWebseite (state, payload) {
    const webseite = state.news.find(webseite => {
      return webseite.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        webseite[key] = payload[key]
      }
    })
  },
  deleteWebseite (state, payload) {
    let array = state.webseiten
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.webseiten = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
